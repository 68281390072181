import React from 'react'
import Layout from '../components/layout'

export default function Offerte() {
    return (
        <Layout>
            I am Offerte
        </Layout>
    )
}
